import React, { useState, useEffect } from 'react';
import Constraint from "../layout/wrapper"

export default function Index() {

  return <div className="bg-gray-bright h-screen">
       <Constraint 
          child={
            <Template/>
            }
          classNames="flex h-screen"
        />
  </div>
}

const Template = ()=>{
  
  const [state, setState] = useState({
    url: "https://admin.ciergio.com/dashboard/login",
    product:"properties"
  });

  return <div className="flex w-full h-screen">
      <div className="w-full md:w-1/2 xl:w-1/3 mx-auto my-auto">
        <div className="mx-auto pb-10">
          <img 
              className="mx-auto"
              src={require("../assets/svg/icn-cg-logo-v.svg")}
              alt=""
              width='240'
          />
        </div>
        <div className="w-full lg: w-3/4 bg-white mx-auto p-10 rounded-sm shadow-xs">
          <p className="text-md text-center text-black text-aileron-bold">
            Choose which Ciergio platform you want to log in to:
          </p>
          <div className="flex w-3/4 py-5 mx-auto text-center">
            
            <div className={`portal-icons-cont cursor-pointer mx-2 w-1/3 ${state.product == "properties" ? "selected":null}`} onClick={()=>{setState({
               ...state,
              url: "https://admin.ciergio.com/dashboard/login",
              product:"properties"
            })}} >
              <div className="rounded-full portal-icons mx-auto">
                <img 
                    className="mx-auto my-5 initial-img"
                    src={require("../assets/svg/icn-properties.svg")}
                    alt=""
                    // width='500'
                />
                <img 
                    className="mx-auto my-5 hover-img"
                    src={require("../assets/svg/icn-properties-hv.svg")}
                    alt=""
                    // width='500'
                />
              </div>
              <p className="text-sm text-center mx-auto my-2">Properties</p>
            </div>

            <div className={`portal-icons-cont cursor-pointer mx-2 w-1/3 ${state.product == "churches" ? "selected":null}`} onClick={()=>{setState({
               ...state,
              url: "https://admin.ciergiopray.com/auth/login",
              product:"churches"
            })}} >
              <div className="rounded-full portal-icons mx-auto">
                <img 
                    className="mx-auto my-3 initial-img"
                    src={require("../assets/svg/icn-church.svg")}
                    alt=""
                    // width='500'
                />
                <img 
                    className="mx-auto my-3 hover-img"
                    src={require("../assets/svg/icn-church-hv.svg")}
                    alt=""
                    // width='500'
                />
              </div>
              <p className="text-sm text-center mx-auto my-2">Churches</p>
            </div>

            <div className={`portal-icons-cont cursor-pointer mx-2 w-1/3 ${state.product == "parks" ? "selected":null}`} onClick={()=>{setState({
               ...state,
              url: "https://admin.thelunetapark.com/dashboard/login",
              product:"parks"
            })}} >
              <div className="rounded-full portal-icons mx-auto">
                <img 
                    className="mx-auto my-3 initial-img"
                    src={require("../assets/svg/icn-parks.svg")}
                    alt=""
                    // width='500'
                />
                <img 
                    className="mx-auto my-3 hover-img"
                    src={require("../assets/svg/icn-parks-hv.svg")}
                    alt=""
                    // width='500'
                />
              </div>
              <p className="text-sm text-center mx-auto my-2">Communities</p>
            </div>

          </div>
          <div>
          <p className="text-md text-center mx-auto my-2">Domain URL</p>
          <p className="text-sm text-center mx-auto my-2 text-gray-700">{state.url}</p>
          </div>
          <a href={state.url} target="_blank"> 
          <div className="w-full rounded-lg bg-cg-orange p-3 mt-5 text-white text-center text-aileron-bold">Continue
          </div>
          </a>
        </div>
      </div>
    </div>
}